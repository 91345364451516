import React from 'react'
import roburnanavbar from '../../images/logo.png'
import '../../App.css'
import { NavLink } from 'react-router-dom'
const Navbar = () => {

  return (
    <div className="container-fluid px-0 nav123">
      <div className="mx-sm-4 mx-2">
        <nav className="navbar navbar-expand-lg  navbar-dark bg-transparent">
          <img src={roburnanavbar} alt="..." style={{ height: '90px' }} />
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <div className="navbar-nav d-block d-lg-flex ml-auto">
              <NavLink to="/" className="btn btn-home" style={{ fontWeight: 600 }}>Home</NavLink>
            </div>
          </div>
        </nav>
      </div>
    </div>
  )
}
export default Navbar