import React from "react"
import Navbar from './components/navbar/navbar'
import './App.css'
import { BrowserRouter, Route, Routes } from "react-router-dom"
import Footer from './components/footer/footer'
import Faucet from "./components/faucet/faucet"

const Main = () => {
    return (
        <>
            <div className="main-bg-pic w-100">
                <BrowserRouter>
                    <Navbar></Navbar>
                    <Routes>
                        <Route path="/" element={<Faucet></Faucet>} exact={true} />
                    </Routes>
                    <Footer></Footer>
                </BrowserRouter>
            </div>
            <div className="bg-left" />
            <div className="bg-right" />

        </>
    )
}
export default Main