import React from "react";

const Footer = () => {
  return (
    <div className="container-fluid px-0 bg-footer">
      <div className="footer-mobile d-flex justify-content-between mx-sm-4 mx-2 py-1 py-sm-3 px-3">
        <div className="footer-last-section-width">
          <p className="mb-0 py-3 text-sm-right text-bold" style={{ fontSize: '20px', fontWeight: 'bold' }}>Roburna Chain © 2023</p>
        </div>
        <div className="d-flex align-items-center">
          <a href="https://twitter.com/Roburnaofficial">
            <i style={{ fontSize: '20px', cursor: 'pointer', color: '#212529' }} className="fab fa-twitter mx-2"></i>

          </a>
          <a href="https://www.youtube.com/channel/UCVv6mQ8Zw3sQYfJ3hG5mU7w">
            <i style={{ fontSize: '20px', cursor: 'pointer', color: '#212529' }} className="fab fa-youtube mx-2"></i>

          </a>
          <a href="https://t.me/roburna"><i style={{ fontSize: '20px', cursor: 'pointer', color: '#212529' }} className="fab fa-telegram-plane  mx-2"></i></a>
        </div>
      </div>
    </div>
  );
};
export default Footer;
