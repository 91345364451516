import React, { useState, useEffect } from 'react'
import './faucet.css'
import { Progress, Button } from 'reactstrap'
import axios from "axios";
import moment from "moment";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_URL } from '../../config';

const Faucet = () => {

    useEffect(() => {
        getData();
    }, [])


    const [Block, setBlock] = useState("");
    const [Count, setCount] = useState("");
    const [Balance, setBalance] = useState("");
    const [Receiver, setReceiver] = useState("");
    const [TX, setTX] = useState([]);

    useEffect(() => {
        if (TX.length > 0) {
            getData();
        }
    }, [TX.length])

    function getData() {
        axios.get(`${API_URL}getTx`)
            .then(function (response) {
                console.log(response.data);
                setTX(response.data.result);
                setBalance(response.data.balance)
                setCount(response.data.count)
                setBlock(response.data.blockNumber)
            })
            .catch(function (error) {
                console.log(error);
            })
    }
    function sendData(tokenType) {

        if (Receiver.length === 0) return alert("Enter wallet address")
        if (Receiver.length !== 42) return alert("Invalid wallet address")

        const data = {
            receiver: Receiver,
        }

        axios.post(`${API_URL}send-rba`, data)
            .then(function (response) {
                console.log(response);
                if (typeof response.data.error != "undefined") {
                    notify(response.data.error, toast.error)
                } else {
                    notify("Your faucet request is being processed.", toast.success)
                    setReceiver("");
                    getData();
                }

            })
            .catch(function (error) {
                console.log(error);
            })
    }
    const notify = (response, types) =>
        types(response, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

    return (
        <div className="container-fluid">
            <div className="row mx-2 mx-sm-0 d-flex align-items-center " style={{ height: '' }}>
                <div className="col-12 px-0 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                    <h1 className="text-gradient-main">Roburna Chain Faucet</h1>
                    <div className="d-flex my-3 cardHome123">
                        <div className="input-group formPart">
                            <input type="text" className="form-control input-bg-form py-2 px-3" value={Receiver} onChange={(e) => setReceiver(e.target.value)} required placeholder="Input your Roburna chain address" aria-label="Username" aria-describedby="basic-addon1" />
                        </div>
                        <div>
                            <Button className='btn-gold-gradient formPart-btn' onClick={() => sendData("rba")}>Give me RBA</Button>
                        </div>
                        {/* <div>
                            <Button className='btn-gold-gradient formPart-btn' onClick={() => sendData("wrba")}>Give me WRBA</Button>
                        </div> */}
                        <ToastContainer style={{ width: "max-content" }} />
                    </div>
                    <div className="cardHome123" style={{ flexWrap: "wrap" }}>

                        {TX.map((item, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <div className="row txList">
                                        <div className="col-md-8 ">
                                            <div className="p-2  text-center text-white" style={{ borderRadius: '8px', wordBreak: 'break-all' }}> {item.wallet} </div>
                                        </div>
                                        <div className="col-md-4 timeSec">
                                            <p className="text-white text-center "> {moment(parseInt(item.dateTime)).fromNow()} </p>
                                            <Progress value={50} />
                                        </div>
                                    </div>
                                    <hr className="my-0" style={{ borderTop: '1px solid white' }}></hr>
                                </React.Fragment>
                            )
                        })}




                        <div className="row px-3">
                            <div className="col-12 col-sm-6 col-lg-2 text-center text-white py-lg-3 py-3 px-2"><i className="gold-color fal fa-wifi"></i> 1 Peers</div>
                            <div className="col-12 col-sm-6 col-lg-3 text-center text-white py-lg-3 py-3 px-2"><i className="gold-color fal fa-cubes"></i> {Block} Blocks</div>
                            <div className="col-12 col-sm-6 col-lg-4 text-center text-white py-lg-3 py-3 px-2"><i className="gold-color fas fa-heartbeat text-white"></i> {(Balance / 1000000000000000000).toFixed(5)} RBAs</div>
                            <div className="col-12 col-sm-6 col-lg-3 text-center text-white py-lg-3 py-3 px-2"><i className="gold-color fal fa-warehouse"></i> {Count} Funded</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Faucet
