import Main from './main'

function App() {
  return (

    <Main></Main>

  );
}

export default App;
